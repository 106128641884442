import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <h2 className={classes.title}>Mission & Vision</h2>
          <h5 className={classes.description}>
          We at Metropole Laboratories Private Limited, are committed to provide accurate, credible, cost-competitive and timeous laboratory services by conforming customer confidentiality and satisfaction.
We are striving to follow the best medical & ethical practices that comply with the pertinent quality management system with continual improvement in quality services and staff development.
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6} style={{height:'50%'}}>
          <img
                src={require("assets/img/mission.jpeg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem>
      </GridContainer>
     
     
    </div>
  );
}
