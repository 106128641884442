import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img_stock/bio_chem_1.JPG";
import team2 from "assets/img/faces/christian.jpg";
import team3 from "assets/img/faces/kendall.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Departments</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team1} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Clinical Biochemistry
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our Clinical Biochemistry department is equipped with state of the art Cobas 311 by Roche diagnostics Switzerland for routine biochemistry tests.  For endocrinology, Tumor markers, Vitamins & infectious diseases we have installed Cobas E411 by Roche diagnostics Switzerland. And for specialty immunoassays we have state of the Art analyzer I-Sys by IDS France.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/immunology_1.JPG")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Immunology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                This department is well equipped with world’s leading brands of specialty immunoassay and autoimmunity testing systems. These installations includes Alegria by Orgentec Germany, Chorus by Diesse Italy, Blue Diver by D-Tek Belgium. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/mic_1.jpg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Hematology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our Hematology department is equipped with State of the art hematology analyzer Cell-Dyn by Abbott diagnostics USA , Cube 30 Touch automated ESR system by  Diesse Italy ,Thrombolyzer Automated hemostasis system by Behnk electronics Germnay & automated capillary electrophoresis system by Sebia France.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require('assets/img_stock/Molecular_Genetics.JPG')} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Clinical Pathology & Microbiology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our Clinical pathology & Microbiology department is equipped with Cobas U411 for urine analysis, fully automated Sperm health analyzer by Biola Russia , Automated  blood culture system  by BD USA. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/histopathology_1.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Histopathology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our panel of highly qualified histopathologists are helping the clinicians & surgeons by making tissue diagnosis for better patient care and manageability
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/moleculer_dep_1.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Molecular Biology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our Molecular biology department is equipped with Cepheid Gene expert molecular system for the detection and viral loads of many viral & Bacterial pathogens including Hepatitis, B , Hepatitis C , HIV &  Mycobacterium Tuberculosis.  Our Gene expert system also provides Drug resistance to Tuberculosis.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/pathology_2.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Special Pathology
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                In our special pathology department we are performing Cytogenetic studies, Platelet aggregation studies, Interferon gamma release assay ( T SPOT TB Test) for the accurate diagnosis of Tuberculosis , Immunoflourescence studies for Anti-nuclear antibodies, Urea Breath test for accurate diagnosis of H.Pylori infections, Hydrogen Breath Tests for the intolerances of different sugars.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/animations/virus (12).png")} alt="..." 
                  alt="..."
                  width={"70%"}
                  className={classes.imgFluid+" "+"funfact-one__virus-1"}
                />
              </GridItem>
              <h4 className={classes.cardTitle}>
              Covid 19 PCR Lab
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                MPL has established a dedicated Covid-19 PCR testing Laboratory. This  BSL-2 Covid lab is equipped with state of the art PCR systems by Hibergene diagnostics Ireland. The high quality CE marked and IVD approved kits are being used for testing which are also made by Hibergene Diagnostics Ireland. Currently we have a PCR testing capacity of 2000 per day.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img_stock/molecular_gen_2.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Molecular Genetics
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                Our molecular genetics department is lead by Dr.Hani Akbar, a well-known clinical geneticist. We provide counseling and diagnostic facilities of genetic disorders to Patients and clinicians. We are collaborating with different international laboratories for outsource testing of genetic markers.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
           
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={require("assets/img/x-ray.jpeg")} alt="..." style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "calc(.25rem - 1px)",
                    boxShadow:
                    "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                }}/>
              </GridItem>
              <h4 className={classes.cardTitle}>
              Imaging & Radiology Department
                <br />
               </h4>
              <CardBody>
                <p className={classes.description}>
                In our Imaging & Radiology department we are providing digital X-Ray & Ultrasound facilities to our customers with the help of our highly qualified panel of Radiologists and sonologists . We are equipped with latest X-Ray and ultrasound machines to meet the clinician’s needs. 
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
         
          </GridItem>
        </GridContainer>
        <GridContainer >
          <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
          <h2 className={classes.title}>Our Prime Location</h2>
           </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'50%'}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53115.91986215992!2d73.026057615748!3d33.68966667301035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbf43bd76f797%3A0xada34418cc053a99!2sMetropole%20Laboratories%20Private%20Limited!5e0!3m2!1sen!2s!4v1598854374278!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" />
             
          </GridItem>
          </GridContainer>
      </div>
    </div>
  );
}
