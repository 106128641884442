/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload, ImportantDevices } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import url from '../../link'
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  // if patient login then display this main menu 
  var Main_Menu= localStorage.getItem('role')=='Patient' ?
  [ 
  // view => landingpage=>find a test
  <NavLink to="/FindTest" className={classes.dropdownLink}>
  Find a Test
  </NavLink>,
  // view => labReports=>labReports
  <NavLink to="/Lab_Reports" className={classes.dropdownLink}>
Lab Reports
</NavLink>,
<NavLink to="/COVID19_HomeSampling"
style={{
  backgroundColor:'red',
  color:'white',
  padding:5,
  fontWeight:'bold'
}}
// these classes are called from this path import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
className={classes.dropdownLink}>
Book COVID-19 Free Home Sampling
</NavLink>,
<NavLink to="/HomeSampling" className={classes.dropdownLink}>
Book Free Home Sampling
</NavLink>,
<NavLink to="/Booking_History" className={classes.dropdownLink}>
Booking History
</NavLink>,
<NavLink to="/ResetPassword" className={classes.dropdownLink}>
Reset Password
</NavLink>]
:
[<NavLink to="/FindTest" className={classes.dropdownLink}>
Find a Test
</NavLink>,
<NavLink to="/Lab_Reports" className={classes.dropdownLink}>
Lab Reports
</NavLink>,
<NavLink to="/ResetPassword" className={classes.dropdownLink}>
Reset Password
</NavLink>]

  var ksa = localStorage.getItem('patient') ?
  (!localStorage.getItem('patient_name').toLowerCase().includes("mpl-") && (localStorage.getItem('patient_id')=="najam" || localStorage.getItem('patient_id')=="najam1" ))  ? 
  [<NavLink to="/KSA_Reports" className={classes.dropdownLink}>
  KSA Summary
</NavLink>,
<NavLink to="/Overall_Covid_Reports" className={classes.dropdownLink}>
Overall Covid-19 Summary
</NavLink>
,
<NavLink to="/ExportKSACases" className={classes.dropdownLink}>
KSA Positive Cases
</NavLink>
,
<NavLink to="/QatarExcelExport" className={classes.dropdownLink}>
Qatar Airways Summary
</NavLink> 
,
<NavLink to="/Bookings" className={classes.dropdownLink}>
Booking Counter
</NavLink>
,
<NavLink to="/Overall_Audit" className={classes.dropdownLink}>
Audit of Passenger cases (24 Hours) Destination Wise
</NavLink>
,
<NavLink to="/Travelers" className={classes.dropdownLink}>
Travelers Details Export
</NavLink>
,    

<NavLink to="/FlyDubaiExcelExport" className={classes.dropdownLink}>
FlyDubai Summary
</NavLink>
,

<NavLink to="/AirBlue_Export" className={classes.dropdownLink}>
AirBlue Summary Export
</NavLink>
,

 <NavLink to="/View_Sample_Tracking" className={classes.dropdownLink}>
 View Sample Tracking
 </NavLink>
 ,
 <NavLink to="/GerryOnlyExcelExport" className={classes.dropdownLink}>
 Excel Export of Gerry
   </NavLink>
   ,  
 <NavLink to="/GerrysExcelExport" className={classes.dropdownLink}>
 Gerry's Summary
 </NavLink>
] 
:
( localStorage.getItem('patient_name').toLowerCase().includes("mpl-")  && localStorage.getItem('role')=='Admin' )
?
[ ]

:[]:[]

  return (
    <List className={classes.list}>
      
      {/* Home for all Header that are honePage in index.js*/}
      <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#196c90'}}
          
        >
          <NavLink to="/" 
          style={{color:'white'}}
          >Home </NavLink>
        </Button>
      </ListItem>
      {/* NewsAndFeeds 31-dec-2022 */}
      <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#196c90'}}
          
        >
          <NavLink to="/NewsAndFeed" 
          style={{color:'white'}}
          >News&Feed </NavLink>
        </Button>
      </ListItem>
     {/* Service part in Header that are drop down when we click its move to that screen define in index.js "/Clinic_Chem" etc */}
      <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="Services"
          style={{backgroundColor:'#196c90'}}
          className={classes.navLink}
      
          dropdownList={[
            <NavLink to="/Clinic_Chem" className={classes.dropdownLink}>
   Clinical Bio-Chemistry 
            </NavLink>,
             <NavLink to="/Immunology" className={classes.dropdownLink}>
Immunology
             </NavLink>,
              <NavLink to="/Hematology" className={classes.dropdownLink}>
              Haematology
                           </NavLink>,
              <NavLink to="/Microbiology" className={classes.dropdownLink}>
                 Clinical Pathology & Microbiology

                </NavLink>,
              <NavLink to="/Histopathology" className={classes.dropdownLink}>
                     
Histopathology

                        </NavLink>,
              <NavLink to="/Molecular" className={classes.dropdownLink}>
                      Molecular Biology
                        </NavLink>,
                        <NavLink to="/Special_Pathology" className={classes.dropdownLink}>
                        Special Pathology
                          </NavLink>,
                         <NavLink to="/Covid" className={classes.dropdownLink}>
                        Covid 19 Molecular Lab
                        </NavLink>,
<NavLink to="/Molecular_Genetics" className={classes.dropdownLink}>
Molecular Genetics 
</NavLink>,
<NavLink to="/Imaging" className={classes.dropdownLink}>
Imaging & Radiology Department
</NavLink>


          ]}
        />
      </ListItem>
      {/* if login person are not patient then show */}
      {
        localStorage.getItem('role')!='Patient' ?
        <ListItem className={classes.listItem}style={{marginRight:'20px', textAlign:'center'}}>
        <CustomDropdown
          buttonText="About Us"
          style={{backgroundColor:'#196c90 !important'}}
          className={classes.navLink}
          dropdownList={[
            <NavLink to="/WhoWeAre" className={classes.dropdownLink}>
   Who We Are
            </NavLink>,
             <NavLink to="/OurMission" className={classes.dropdownLink}>
Mission & Vision
             </NavLink>
              

          ]}
        />
      </ListItem>
      :null
      }
       {/* if login person are  patient then show patient Id and passworn on diary */}
      {
        localStorage.getItem('role')=='Patient' ?
        <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
      <Button
        className={'online_reports'}
      >
        {/* this COVID19_HomeSampling is in view =>COVID19_HomeSampling=>HomeSampling*/}
        <NavLink to="/COVID19_HomeSampling" 

        style={{color:'white'}}
      

      >Book COVID-19 Home Sampling</NavLink>
      </Button>
    </ListItem>
      : null
      }
      {/* onLine Reports me login ni hona chahaye when click on this go to Login page */}
      {(localStorage.getItem('patient')==null && localStorage.getItem('emirates')==null && localStorage.getItem('qatar')==null && localStorage.getItem('flydubai')==null && localStorage.getItem('onix')==null) && localStorage.getItem('airblue')==null && localStorage.getItem('etihad')==null && localStorage.getItem('OmanAir')==null && localStorage.getItem('SereneAir')==null ? 
      
      <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
      <Button
        className={'online_reports'}
        
      >
        <NavLink to="/Login" 

        style={{color:'white'}}
      

        >Online Reports</NavLink>
      </Button>
    </ListItem>
    : <></>
    }
    {/* if localStorage have emirates then only show 3 main menu item */}
       {localStorage.getItem('emirates') ?
       
       <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="Main Menu"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,
<NavLink to="/Quotation" className={classes.dropdownLink}>
Quotation
</NavLink>,
//  /summary in view =>Emirates_KSA_Summary =>Summary
          <NavLink to="/Summary" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
   localStorage.getItem('flydubai') ?
   <>
    {/* if localStorage have flydubai then only show 3 main menu item */}

       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="Main Menu"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,

<NavLink to="/Quotation" className={classes.dropdownLink}>
Quotation
</NavLink>,
          <NavLink to="/FlyDubai_Covid19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
   localStorage.getItem('airblue') ?
   <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="Main Menu"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,

<NavLink to="/Quotation" className={classes.dropdownLink}>
Quotation
</NavLink>,
          <NavLink to="/AirBlue_COVID19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
   
   localStorage.getItem('etihad') ?
   <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="Main Menu"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,

<NavLink to="/Quotation" className={classes.dropdownLink}>
Quotation
</NavLink>,
          <NavLink to="/EtihadAirways_COVID19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
   localStorage.getItem('OmanAir') ?
   <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="Main Menu"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,

<NavLink to="/Quotation" className={classes.dropdownLink}>
Quotation
</NavLink>,
          <NavLink to="/OmanAir_COVID19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
   localStorage.getItem('SereneAir') ?
   <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="Main Menu"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,

<NavLink to="/Quotation" className={classes.dropdownLink}>
Quotation
</NavLink>,
          <NavLink to="/SereneAir_COVID19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   
   :
   localStorage.getItem('onix') ?
   <>
       <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
     <CustomDropdown
       buttonText="Main Menu"
       style={{backgroundColor:'#196c90'}}
       className={classes.navLink}
       dropdownList={[
         <NavLink to="/FindTest" className={classes.dropdownLink}>
         Find a Test
         </NavLink>,

<NavLink to="/Quotation" className={classes.dropdownLink}>
Quotation
</NavLink>,
          <NavLink to="/OnixEnterprises_COVID19_PCR" className={classes.dropdownLink}>
          COVID-19 by PCR
          </NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
 localStorage.clear()
 setTimeout(()=>{
   window.location.reload()
 },1000)
 window.location.href=url

}}
className={classes.dropdownLink}>
Logout
</Button>


       ]}
     />
   </ListItem>
    
   </>
   :
    localStorage.getItem('qatar') ?
    <>
        <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
      <CustomDropdown
        buttonText="Main Menu"
        style={{backgroundColor:'#196c90'}}
        className={classes.navLink}
        dropdownList={[
          <NavLink to="/FindTest" className={classes.dropdownLink}>
          Find a Test
          </NavLink>,
 
 <NavLink to="/Quotation" className={classes.dropdownLink}>
 Quotation
 </NavLink>,
           <NavLink to="/Qatar_COVID19_PCR" className={classes.dropdownLink}>
           COVID-19 by PCR
           </NavLink>,
 <Button 
 style={{textAlign:'center', width:'100%'}}
 onClick={()=>{
  localStorage.clear()
  setTimeout(()=>{
    window.location.reload()
  },1000)
  window.location.href=url
 
 }}
 className={classes.dropdownLink}>
 Logout
 </Button>
 
 
        ]}
      />
    </ListItem>
     
    </>
   :
       
       localStorage.getItem('patient') && localStorage.getItem('role')!='Admin' ? 
         <>
          <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
        <CustomDropdown
          buttonText="Main Menu"
          style={{backgroundColor:'#196c90'}}
          className={classes.navLink}
          dropdownList={[
            // ye menu oper define hn for patient 27
           ...Main_Menu,
<Button 
style={{textAlign:'center', width:'100%'}}
  onClick={()=>{
    localStorage.clear()
    setTimeout(()=>{
      window.location.reload()
    },1000)
    window.location.href=url
   
  }}
className={classes.dropdownLink}>
  Logout
</Button>


          ]}
        />
      </ListItem>
       
      </>
      :
      // IF login person are Admin and patient both 
      localStorage.getItem('patient') && localStorage.getItem('role')=='Admin' ?
      <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
      <CustomDropdown
        buttonText="Main Menu"
        style={{backgroundColor:'#196c90'}}
        className={classes.navLink}
        dropdownList={[
          <NavLink to="/FindTest" className={classes.dropdownLink}>
          Find a Test
          </NavLink>,
          <NavLink to="/Lab_Reports" className={classes.dropdownLink}>
Lab Reports
</NavLink>,

<NavLink to="/Quotation" className={classes.dropdownLink}>
Quotation
</NavLink>
,
...ksa
,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
  localStorage.clear()
  setTimeout(()=>{
    window.location.reload()
  },1000)
  window.location.href=url
 
}}
className={classes.dropdownLink}>
Logout
</Button>


        ]}
      />
    </ListItem>
      :
      // ye pateint admin k ilawa sab k lye jo website open kre ga
      <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
      <CustomDropdown
        buttonText="Main Menu"
        style={{backgroundColor:'#196c90'}}
        className={classes.navLink}
        dropdownList={[
          <NavLink to="/FindTest" className={classes.dropdownLink}>
          Find a Test
          </NavLink>,
          <NavLink to="/Quotation" className={classes.dropdownLink}>
Quotation
</NavLink>,
<NavLink to="/QatarLogin" className={classes.dropdownLink}>
Qatar Airways
</NavLink>,
<NavLink to="/Emirates" className={classes.dropdownLink}>
Emirates
</NavLink>,
<NavLink to="/FlyDubai" className={classes.dropdownLink}>
FlyDubai
</NavLink>,
<NavLink to="/AirBlue" className={classes.dropdownLink}>
Air Blue
</NavLink>,
<NavLink to="/SereneAir" className={classes.dropdownLink}>
Serene Air
</NavLink>,
<NavLink to="/OmanAir" className={classes.dropdownLink}>
Oman Air
</NavLink>,
<NavLink to="/Onix_Enterprises" className={classes.dropdownLink}>
Onix Enterprises
</NavLink>
        ]}
      />
    </ListItem>
      
      }
       {(localStorage.getItem('patient') || localStorage.getItem('emirates') || localStorage.getItem('qatar') ||  localStorage.getItem('flydubai') || localStorage.getItem('airblue') || localStorage.getItem('etihad') || localStorage.getItem('OmanAir') || localStorage.getItem('SereneAir') || localStorage.getItem('onix'))  ? 
       <ListItem className={classes.listItem} style={{textAlign:'center'}}>
       <Button
          style={{backgroundColor:'white',   boxShadow:
          "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
      }}
        
       >
       <img src={require('assets/img/welcome.png')}
       style={{width:'3em'}}
       />
      <b style={{color:'black'}}> Welcome
      
        <br />{localStorage.getItem('patient_name')==null && localStorage.getItem('emirates') ?  "Emirates" : localStorage.getItem('flydubai') ?
        "FlyDubai" : localStorage.getItem('airblue') ? "Air Blue"  : localStorage.getItem('etihad') ? 'Etihad Airways' : localStorage.getItem('OmanAir') ?  "Oman Air"  :
        localStorage.getItem('qatar') ? "Qatar" : localStorage.getItem('SereneAir') ? "Serene Air" :  localStorage.getItem('onix') ? "Onix Enterprises" 
        :localStorage.getItem('patient_name')}</b>

       </Button>
     </ListItem>
      :<></> }
      
    </List>
  );
}
