/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import url from '../../link'

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <>
   {window.location.href!="https://backup.mpl-labs.pk/ResetPassword" && window.location.href!="https://backup.mpl-labs.pk/FlyDubai" && window.location.href!="https://backup.mpl-labs.pk/AirBlue" && window.location.href!="https://backup.mpl-labs.pk/Login" && window.location.href!="https://backup.mpl-labs.pk/Emirates" && window.location.href!="https://backup.mpl-labs.pk/Register" && window.location.href!="https://backup.mpl-labs.pk/RecallPassword" ?
    <footer className={footerClasses} style={{width:'100%'}}>
    <GridContainer  style={{width:'100%',marginRight:0,marginLeft:0}}>
    <GridItem xs={1} sm={1} md={1} lg={1} >
      <></>
      </GridItem>
      <GridItem xs={8} sm={8} md={8} lg={8} style={{margin:'auto'}} >
      <h2 style={{fontWeight:'bold'}}>Contact Us</h2>
      <h5>Where to Find Us</h5>
      <p>Metropole Laboratories Private Limited
20-C, Cresent Arcade, G-8 Markaz,
Islamabad Capital Territory</p>
      <h5>Email Us At</h5>

      <a href="mailto:info@mpl-labs.pk">info@mpl-labs.pk</a>
      <h5>Call Us At</h5>
      <a href="tel:051111988988">051-111-988-988</a>
        </GridItem>
        <GridItem xs={1} sm={1} md={1} lg={1} >
<></>
        </GridItem>
      </GridContainer>
      <br />
      </footer>
   :<></>}

 <footer className={footerClasses}>
      <div className={classes.container}>
       
         
            Metropole Laboratories PVT LTD 
            &copy; {1900 + new Date().getYear()}
      
      </div>
    </footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
