import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Reports from './Reports'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
// Sections for this page
import molecule_1 from 'assets/img/animations/virus (13).png';


import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';


import report from 'assets/img/lab_test.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [LabReports,setReports]=React.useState('')
  useEffect(()=>{
    if(localStorage.getItem('role')=='Admin' && !localStorage.getItem('patient_name').toLowerCase().includes('mpl-')){
        setReports('true')
       
    }else{
        window.location.href="https://backup.mpl-labs.pk/" 
    }
     },[])
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/info.jpeg")} style={{maxHeight:'25em'}}>
        <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            <img src={molecule_1} className="funfact-two__virus" alt="" style={{
  width: '5em',
  height: 'auto', right:'30em'}}/>
       <img src={molecule_6} className="funfact-two__virus__5" alt="" style={{  width: '5em',
  height: 'auto',}}/>
       <img src={molecule_7} className="funfact-two__virus__7" alt="" style={{ width: '5em',
  height: 'auto',top:'10em'}}/>   
      
       
           
              <h1 className={classes.title}>Gerry's Cases</h1>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"5em"}} >
            
          <br />
          <img src={report} style={{
  width: '7em',}} class="center"/>
          {LabReports=='true' ?   <Reports /> : <></>}
            <FreeHS_Msg />
        </div>
      </div>
      <Footer />
    </div>
  );
}
