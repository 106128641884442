import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { NavLink } from "react-router-dom";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import Slide from "@material-ui/core/Slide";

import TextField from '@material-ui/core/TextField';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  
export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [classicModal, setClassicModal] = React.useState(false);
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const [gender,setGender]= React.useState('Select');
  const [firstName, setfirstName]=React.useState('');
  const [lastName, setlastName]= React.useState('');
  const [MobileNo, setMobileNo]=React.useState('')
  const [ServerResponse, setServerResponse]=React.useState('')
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader  className={classes.cardHeader} style={{backgroundColor:'#196c90'}}>
                    <h4 style={{color:'white'}}>Patient Recall Password</h4>
                    
                  </CardHeader>
                  <CardBody>
                 
                  <TextField required id="" block
                  fullWidth={true}
                  label="First Name"
                  value={firstName}
                  onChange={(e)=>{
                    setfirstName(e.target.value)
                  }} 
                  />
                <br />
                <br />
                
                <TextField required id="" block
                  fullWidth={true}
                  label="Last Name"
                  value={lastName}
                  onChange={(e)=>{
                    setlastName(e.target.value)
                  }} 
                  />
                <br />
                <br />
                <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Gender</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={gender}
          onChange={(e)=>{
       setGender(e.target.value)
          }}
        >
        <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          <MenuItem value={"Male"}>Male</MenuItem>
          <MenuItem value={"Female"}>Female</MenuItem>
        </Select>
      </FormControl>
              
                <br />
                <br />
               
                    <TextField required id="" block
                  fullWidth={true}
                  label="Mobile Number"
                  type="number"
                  value={MobileNo}
                  onChange={(e)=>{
                    setMobileNo(e.target.value)
                  }} 
                  />
                
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button color="#196c90" size="lg" style={{backgroundColor:'#196c90'}}
                     onClick={() => {
                       if(firstName.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter First Name !!')
                        return
                       }
                       if(lastName.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Last Name !!')
                        return
                     
                       }
                       if(gender=='Select'){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Select Gender !!')
                        return
                     
                       }
                       var number= MobileNo+""
                       if(number.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Phone Number !!')
                        return
                     
                       }
                      fetch('http://reports.mpl-labs.pk:8443/4DACTION/WebPortal_Password_Retrieve?first_name='+firstName+'&last_name='+lastName+'&gender='+gender+'&mobile_number='+number).then((response) => {
                          return response.json()
                       }).then((res)=>{
                        
      if(res.Result){
        setServerResponse('Your Credentials have been SMS & Email to you !')
        setfirstName('')
        setlastName('')
        setGender('Select')
        setMobileNo('')
        setClassicModal(true)
      }
      else{
        setServerResponse('Sorry, Invalid Detail !')
        setClassicModal(true)
      }
                      })                  
                      }}
                     >
                      Recall
                    </Button>
                    
                  </CardFooter>
                </form>
                <Button simple size="lg" color="primary">
                    <NavLink to="/">
                      Login as Patient
                      </NavLink>
                    </Button>
                    
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={4}>

            <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={classicModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Patient Recall Password</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                   {ServerResponse}
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setClassicModal(false)}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
          </GridContainer>

        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
