import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionBasics from "./Sections/SectionBasics.js";
import SectionNavbars from "./Sections/SectionNavbars.js";
import SectionTabs from "./Sections/SectionTabs.js";
import SectionPills from "./Sections/SectionPills.js";
import SectionNotifications from "./Sections/SectionNotifications.js";
import SectionTypography from "./Sections/SectionTypography.js";
import SectionJavascript from "./Sections/SectionJavascript.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
import SectionLogin from "./Sections/SectionLogin.js";
import SectionExamples from "./Sections/SectionExamples.js";
import SectionDownload from "./Sections/SectionDownload.js";
import FreeHS_Msg from './FreeHS_Msg'

import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);
export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  
  return (
    <div >
     
      <Header
        brand="Metropole Laboratories Private Limited"
        //rightLink me headerLink pass kr rhe hn jo show ho rhy hn Home,Services,About Us etc on the base of login patient ,admin etc 
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "black"
        }}
        {...rest}
      />
     <div style={{marginTop:'8em'}}>
     <div style={{width:'100%'}}>
     
     <SectionCarousel />
     
       </div>
       </div>
     
    
   
{/* these classes.main are call from import styles from "assets/jss/material-kit-react/views/components.js"; */}
      <div className={classNames(classes.main, classes.mainRaised)}>
          <ProductSection />
          {/* Deapartment section are in this TeamSection */}
          <TeamSection />
       {/* thses are Free HomSampling  section that arae display in screen*/}
          <FreeHS_Msg />
      </div>
      <Footer />
    </div>
  );
}
