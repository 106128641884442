import React,{useState,useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import picsample from "../../assets/img/airblue.jpeg"

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Lab_Tests from "../Lab_Reports/Lab_Tests"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
// import ProductSection from "./Sections/ProductSection.js";
// import TeamSection from "./Sections/TeamSection.js";
// import WorkSection from "./Sections/WorkSection.js";
import molecule_1 from 'assets/img/animations/virus (13).png';



import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';

import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
import test_info from 'assets/img/test_info.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function NewsAndFeed(props) {
    const [newsAndFeeds, setnewsAndFeeds] = useState([]);

  const classes = useStyles();
  var tesTmg= "data:image/png;base64,";

  const { ...rest } = props;
  useEffect(()=>{
    // window.scrollTo(0,0);
    
   NewsAndFeedsHandler()
    
 },[])
 const NewsAndFeedsHandler =async()=>{
  
    // setloading(true)
      
        var d = new Date()
  
    
    
        var strDate=d.toISOString().slice(0,-5)
         console.log(strDate)
    
          
        
    
            //  let dataFive = await  fetch("http://reports.mpl-labs.pk:8443/4DACTION/WebsiteShowSupEventsNEWS");
             let dataFive = await  fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebsiteShowSupEventsNEWS");
           
            let responseData5 = await dataFive.json();
            console.log('data from the api is Single Products ###### : ',responseData5);

             setnewsAndFeeds(responseData5)
            // fetch("http://reports.mpl-labs.pk:8443/4DACTION/WebsiteShowSupEventsNEWS").then(res => res.json()).then((response) => {
            //     // this.setState({Branches : response})
            //     // setnewsAndFeeds()
            //     console.log("Response of API 321 BranchesLoadAllfromWebserver :",response)
      
            //   })
            
       
    
            // console.log('data from the api is Single Products ###### : ',newsAndFeeds);
    
            
         
       }
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/info.jpeg")} style={{maxHeight:'30em'}}>
        <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            <img src={molecule_1} className="funfact-two__virus" alt="" style={{
  width: '5em',
  height: 'auto', right:'30em'}}/>
       <img src={molecule_6} className="funfact-two__virus__5" alt="" style={{  width: '5em',
  height: 'auto',}}/>
       <img src={molecule_7} className="funfact-two__virus__7" alt="" style={{ width: '5em',
  height: 'auto',top:'10em'}}/>   
       
           
              <h1 className={classes.title}>News And Feeds</h1>
              <h4>
               Latest News and Feeds that are relevent to MPL.
              </h4>
              <br />
            
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"5em"}} >

          {/* <br />
          <img src={test_info} style={{
  width: '7em',}} class="center"/> */}
  {/* table call ho rha in which api call and fill the data state and display table in this screen */}
            {/* <Lab_Tests /> */}
    <div className='container-fluid  m-0 p-0 w-100'>
        {/* mt-5 tha yhan */}
    <div className='row mt-0 m-0 p-0'>
     
          <div className='col-12 m-0 p-0 d-flex align-items-center justify-content-center'>
          <h1 className='mb-3'  style={{color:"#3c4858",fontWeight:"bold"}}>News and Feeds</h1>
    </div>
        
    </div>
    <div className='row d-flex justify-content-center border border-0 border-success m-0 p-0 g-2'>
     <div className='row w-75 d-flex justify-content-around m-0 p-0 border border-0 border-dark' >
     {newsAndFeeds && newsAndFeeds.map((item)=>
 <div class="col-12 col-md-4 mb-3 border border-0 border-danger" style={{}}>
      <div class="p-3 d-flex flex-column align-items-center justify-content-center border bg-light border border-0 border-success shadow" style={{}}>
      <p className='text-center' style={{fontSize:10,fontWeight:"bold",color:"#3c4858"}}>{item.news_date.slice(0,10)}</p>
        <img className='container-fluid mb-2 m-0 p-0'  src={`${tesTmg}`+item.news_picture} alt='test'></img>
        <p className='text-center' style={{fontSize:18,fontWeight:"bold",color:"#3c4858"}}>{item.news_heading}</p>
        <p className='text-center' style={{fontSize:16,color:"#3c4858"}}>{item.news_details}</p>
       

      </div>
    </div>

      )}
     {/* <div class="col-12 col-md-4 mb-3 border border-0 border-danger">
    <div class="p-3 d-flex flex-column align-items-center justify-content-center border bg-light border border-0 border-success shadow">
        <img className='container-fluid mb-3 m-0 p-0' src={picsample} alt='test'></img>
        <h1 className='text-center fw-bold fs-2'>ARE MOODY BARS BANNED?</h1>
    </div>
    </div> */}
     </div> 
   </div>

</div>
            
            {/* this is for Free Home Sampling section */}
            <FreeHS_Msg />
         
        </div>
      </div>
      <Footer />
    </div>
  );
}
