import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <h2 className={classes.title}>Metropole Laboratories Private Limited</h2>
          <h5 className={classes.description}>
          Metropole Laboratories Private Limited is a state of the Art pathology laboratory located in Pakistan . 

We are committed to provide high quality laboratory and radiology services at very competitive prices. 

MPL is proudy to have a team of highly qualified Pathologist, Technologists  & other Allied staff. 

MPL is affiliated with college of American Pathologists (CAP) USA & BIO-RAD EQAS USA for its external quality assurance programs to ensure the highest standards of results to the patients.
          </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6} style={{height:'50%'}}>
          <img
                src={require("assets/img_stock/first_floor.JPG")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem>
      </GridContainer>
     
     
    </div>
  );
}
